<template>
  <section class="bg_color">
    <h2>モーター履歴詳細</h2>
    <section class="inner">
      <div class="outline_motor_dtl">
        <div class="box_motor_dtl">
          <div class="box_thum">
            <figure class="round">
              <img :src="motorImg[detail.mno]" width="" height="" alt="" />
            </figure>
            <p class="name_large">
              <span class="small">モーターNo.</span>{{ Number(detail.mno) }}
            </p>
          </div>
          <div class="dtl_inner">
            <div class="ribon_outline none">
              <div class="ttl_ribon rank">
                <p class="ribon_inner"><Today /></p>
              </div>
            </div>
            <div class="point">
              <span class="small">総獲得モグー</span
              >{{ addComma(Number(detail.total))
              }}<span class="small">モグー</span>
            </div>
            <div class="box_user">
              <p class="user">
                <span class="small">今節の使用者</span>
                <span v-if="detail.current_flg"
                  >{{ detail.current.curret_racer_name }}({{
                    detail.current.curret_racer_kyu
                  }})</span
                >
                <span v-else>-</span>
              </p>
              <p class="race" v-if="detail.current_flg">
                <span class="small">本日の出走レース</span>
                <span
                  class="raceinfo"
                  v-for="val in detail.current.race"
                  :key="val"
                >
                  {{ moment2(val.hdate) }}({{ daily(val.nj) }})第{{
                    val.rno
                  }}R/{{ Number(val.teiban) }}号艇
                </span>
              </p>
              <p class="race" v-else>
                <span class="small">本日の出走レース</span>
                <span class="raceinfo">-</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <h3 class="ttl_main">モーター履歴</h3>
      <!--moter-->
      <div class="box_rank_moter dtl">
        <p class="scroll visible_sp">SCROLL</p>
        <div class="inner" v-if="detail">
          <table>
            <tbody>
              <tr>
                <th colspan="2">使用節</th>
                <th>使用者</th>
                <th>着順</th>
                <th>獲得モグー</th>
              </tr>
              <tr v-for="val in detail.history[0]" :key="val">
                <td>{{ val.grade }}</td>
                <td>
                  <p class="date">
                    {{ moment(val.fdate) }}({{ youbi[val.fdow] }})～{{
                      moment(val.tdate)
                    }}({{ youbi[val.tdow] }})
                  </p>
                  <p class="event">{{ val.section_name }}</p>
                </td>
                <td>{{ val.racer.replace(/\s+/g, "") }}</td>
                <td class="tyaku">
                  <span
                    v-for="v in resultTyaku(val.tyaku)"
                    :key="v"
                    v-html="v.rank"
                  ></span>
                </td>
                <td>
                  <span class="mog">{{ addComma(Number(val.point)) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>表示できる履歴はありません。</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import moment from "moment";
import Today from "@/components/Today.vue";
import { mapGetters } from "vuex";
export default {
  name: "MotorDtl",
  components: {
    Today,
  },
  mixins: [Mixin],
  data: function () {
    return {
      id: this.$route.params.id,
      youbi: ["日", "月", "火", "水", "木", "金", "土"],
      reason: {
        j01: '<img src="/images/parts/j01.svg" class="sikkaku"/>',
        j02: '<img src="/images/parts/j02.svg" class="sikkaku"/>',
        j03: '<img src="/images/parts/j03.svg" class="sikkaku"/>',
        j04: '<img src="/images/parts/j04.svg" class="sikkaku"/>',
        j05: '<img src="/images/parts/j05.svg" class="sikkaku"/>',
        j06: '<img src="/images/parts/j06.svg" class="sikkaku"/>',
        j07: '<img src="/images/parts/j07.svg" class="sikkaku"/>',
        j08: '<img src="/images/parts/j08.svg" class="sikkaku"/>',
        j09: '<img src="/images/parts/j09.svg" class="sikkaku"/>',
        j10: '<img src="/images/parts/j10.svg" class="sikkaku"/>',
        y01: '<img src="/images/parts/y01.svg" class="sikkaku"/>',
        y02: '<img src="/images/parts/y02.svg" class="sikkaku"/>',
        y03: '<img src="/images/parts/y03.svg" class="sikkaku"/>',
        y04: '<img src="/images/parts/y04.svg" class="sikkaku"/>',
        y05: '<img src="/images/parts/y05.svg" class="sikkaku"/>',
        y06: '<img src="/images/parts/y06.svg" class="sikkaku"/>',
        y07: '<img src="/images/parts/y07.svg" class="sikkaku"/>',
        y08: '<img src="/images/parts/y08.svg" class="sikkaku"/>',
        y09: '<img src="/images/parts/y09.svg" class="sikkaku"/>',
        y10: '<img src="/images/parts/y10.svg" class="sikkaku"/>',
      },
    };
  },
  computed: {
    ...mapGetters({
      detail: "getMotorDetailInfo",
      motorImg: "getMotorImg",
    }),
  },
  beforeMount: function () {
    this.$store.dispatch("getMotorDetail", this.id);
  },
  created: function () {},
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("getMotorDetail", this.id);
    }, 15000);
  },
  methods: {
    moment: function (date) {
      return moment(date).format("MM月DD日");
    },
    moment2: function (date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    daily: function (date) {
      let day;
      switch (date) {
        case "1":
          day = "初日";
          break;
        case "2":
          day = "2日目";
          break;
        case "3":
          day = "3日目";
          break;
        case "4":
          day = "4日目";
          break;
        case "5":
          day = "5日目";
          break;
        case "6":
          day = "6日目";
          break;
      }
      return day;
    },
    resultTyaku: function (data) {
      data.forEach((element) => {
        if (element.rank in this.reason) {
          element.rank = this.reason[element.rank];
        }
      });
      return data;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
span.raceinfo {
  display: block;
}
.tyaku /deep/ .sikkaku {
  width: 16px;
  vertical-align: -2px;
}
</style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
